let selectedRegistry = null;

const downloadFileAction = {
	id: 'downloadFileAction',
	selectionType: 'single',
	label: 'puiaction.downloadfile',
	functionality: 'WRITE_PUI_DOCUMENT',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const params = registries[0];
		selectedRegistry = params;
		const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);

		const url = '/documentextrafields/download';
		this.$puiRequests.downloadFileRequest(
			'get',
			url,
			params,
			(response) => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(new window.Blob([response.data]));
				link.download = selectedRegistry.puidocument.filenameorig;
				link.click();

				this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
				selectedRegistry = null;
			},
			() => {
				this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
				var message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title, true);
			}
		);
	}
};

export default {
	actions: [downloadFileAction]
};
